import styled from "styled-components";

export const Container = styled.div`
  max-width: 800px;
  min-height: 96vh;
  margin: 0 auto;
  border: 1px solid black;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  @media Print {
    padding: 0;
    border: none;
  }
`;

export const Header = styled.div`
  font-family: "Open Sans";
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 3rem;
`;

export const AddressBar = styled.div`
  font-family: "Open Sans";
`;

export const PersonInfoBar = styled(AddressBar)`
  margin: 1.5rem 0;
  padding: 0 4rem;
`;

export const P = styled.p`
  margin: 0.1rem 0;
  font-weight: 300;
  display: flex;
  align-items: center;
  font-size: 0.81rem;
`;

export const P1 = styled(P)`
  font-weight: 400;
`;

export const DividerText = styled.p`
  width: ${(props) => (props.width ? props.width : "90%")};
  font-size: ${(props) => (props.size ? props.size : "1rem")};
  text-align: ${(props) => (props.right ? "right" : "left")};
  padding-left: 1rem;
  padding-right: 2rem;
  font-weight: 600;
  font-family: "Open Sans";
  border-bottom: 1.5px solid #dd6d1f;
  margin: ${(props) => (props.margin ? props.margin : "1rem auto")};
  padding-bottom: ${(props) => (props.padding ? props.padding : "0.3rem")};
`;

export const DataDisplayBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.6rem auto;
`;

export const DataDisplayBoxRechnung = styled(DataDisplayBox)`
  width: 96%;
  margin: 0 auto;
`;

export const DataBox = styled.div`
  font-family: "Open Sans";
  text-align: center;
  min-width: 150px;
`;

export const DataHead = styled.div`
  background: #eee;
  font-size: 0.8rem;
`;

export const DataFoot = styled.div`
  font-weight: 300;
  font-size: 0.8rem;
`;

export const Button = styled.input`
  margin: 1.8rem auto;
  padding: 0.6rem 1.5rem;
  display: block;
  font-size: 1.5rem;
  border: 0.5px solid #e76727;
  border-radius: 1rem;
  background: #e76727;
  color: white;
  cursor: pointer;
`;

export const SumContainer = styled.div`
  font-family: "Open Sans";
  width: 360px;
  margin: 3rem 1rem 0 0;
`;
export const SumHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RowText = styled.div`
  width: 60%;
  text-align: right;
  padding-right: 1rem;
  background: ${(props) => (props.bg ? props.bg : "")};
  font-weight: ${(props) => (props.bold ? "600" : "")};
  font-size: 0.81rem;
`;

export const RowNumber = styled.div`
  width: 40%;
  text-align: right;
  padding-right: 1rem;
  background: ${(props) => (props.bg ? props.bg : "")};
  font-weight: ${(props) => (props.bold ? "600" : "")};
  font-size: 0.81rem;
`;

export const TextContainer = styled.div`
  width: 80%;
  text-align: center;
  font-family: "Open Sans";
  margin: 1rem auto;
`;

export const FooterContainer = styled.div`
  width: 96%;
  font-family: "Open Sans";
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  font-size: 0.6rem;
  height: 60px;
  margin-top: auto;
`;

export const DateContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-family: "Open Sans";
  align-items: end;
  margin-bottom: 1rem;
  grid-gap: 3rem;
  font-size: 0.8rem;
  margin-top: 3rem;
`;

export const PrintButton = styled.button`
  cursor: pointer;
  background: #e7692a;
  padding: 0.3rem 0.9rem;
  width: 6rem;
  margin: 0 auto;
  border-radius: 0.3rem;
  color: white;
  font-family: "Open Sans";
  @media print {
    display: none;
  }
`;

export const PostBox = styled.div`
  margin: 0 auto 3rem auto;
`;

export const Sup = styled.sup`
  color: green;
`;

export const ReceiptButton = styled.button`
  margin: 1.8rem 0;
  padding: 0.6rem 1.5rem;
  display: block;
  font-size: 1.5rem;
  border: 0.5px solid #e76727;
  border-radius: 1rem;
  background: #e76727;
  color: white;
  cursor: pointer;
  &:hover {
    color: #e76727;
    background: black;
  }
`;
